import * as ortaBridge from '@orta/bridge';
import { NOT_IN_STORAGE } from '@/config/constants';
// import { executeInQueue } from './bridgeQueue';

window.bridgeApi = ortaBridge;

export const detectedBridgePlatform = ortaBridge.isWebViewBridgeAvailable();

export const getKeycloakToken = async (...args) => {
  try {
    let response = await ortaBridge.getKeycloakToken(...args);
    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const getMe = async () => {
  try {
    let response = await ortaBridge.getMe();
    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const setCustomBackArrowMode = async (...args) => {
  try {
    let response;

    if (detectedBridgePlatform) {
      response = await ortaBridge.backArrow.setMode(...args);
    }

    return response;
  } catch(error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const setCustomBackArrowVisible = async (...args) => {
  try {
    let response;

    if (detectedBridgePlatform) {
      response = await ortaBridge.backArrow.setVisible(...args);
    }

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const setCustomBackArrowOnClickHandler = async (...args) => {
  try {
    let response;

    if (detectedBridgePlatform) {
      response = ortaBridge.backArrow.setOnClickHandler(args[0]);
    }

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const setHeaderMenuItems = async (...args) => {
  try {
    let response;

    if (detectedBridgePlatform) {
      response = await ortaBridge.headerMenuItems.setItems(...args);
    }

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const setHeaderMenuItemClickHandler = async (...args) => {
  try {
    let response;

    if (detectedBridgePlatform) {
      response = ortaBridge.headerMenuItems.setClickHandler(args[0]);
    }

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const disableScreenCapture = async () => {
  try {
    let response = detectedBridgePlatform && await ortaBridge.screenCapture.disable();

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const enableScreenCapture = async () => {
  try {
    let response = detectedBridgePlatform && await ortaBridge.screenCapture.enable();

    return response;
  } catch(error) {
    console.log('error', error);
    return Promise.reject(error);
  }
};

export const setTabActiveHandler = async (fn) => {
  try {
    let response;
    if (detectedBridgePlatform) {
     response = ortaBridge.setTabActiveHandler(fn);
    }

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const getQr = async () => {
  try {
    let response = detectedBridgePlatform && await ortaBridge.getQr();

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const setTitle = async (...args) => {
  try {
    let response = detectedBridgePlatform && await ortaBridge.setTitle(args[0]);

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const shareFile = async (...args) => {
  try {
    console.log('shareFile', args);
    console.log('shareFile args', ...args);
    let response = detectedBridgePlatform && await ortaBridge.shareFile(args[0], args[1], args[2]);

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const share = async (...args) => {
  try {
    let response = detectedBridgePlatform && await ortaBridge.share(args[0]);

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const copyToClipboard = async (...args) => {
  try {
    let response = detectedBridgePlatform && await ortaBridge.copyToClipboard(args[0]);

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const enableNotifications = async () => {
  try {
    let response = await ortaBridge.notifications.enable();
    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const disableNotifications = async () => {
  try {
    let response = await ortaBridge.notifications.disable();
    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const setStorageItem = async (...args) => {
  try {
    let response;

    if (!detectedBridgePlatform && process.env.NODE_ENV === "development") {
      window.localStorage.setItem('STORAGE_'+args[0], args[1]);
    } else if (detectedBridgePlatform){
      console.log('storage argms', `${process.env.VUE_APP_STORAGE_PREFIX}_${args[0]}`, args[1]);

      response = await ortaBridge.storage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}_${args[0]}`, args[1]);

      console.log('setStorage response', response);
    } else {
      console.log('no orta bridge support');
    }

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const getStorageItem = async (...args) => {
  try {
    let response;
    if (!detectedBridgePlatform && process.env.NODE_ENV === "development") {
      response = window.localStorage.getItem('STORAGE_'+args[0]);
    } else if (detectedBridgePlatform){

      console.log('`${process.env.VUE_APP_STORAGE_PREFIX}_${args[0]}`', `${process.env.VUE_APP_STORAGE_PREFIX}_${args[0]}`);
      response = await ortaBridge.storage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}_${args[0]}`);

      console.log('getStorageItem response', response);

    } else {
      console.log('no orta bridge support');
    }

    return response;
  } catch(error) {
    console.log('getStorageItem Error',error);
    if (error?.reason === `cannot find ${process.env.VUE_APP_STORAGE_PREFIX}_${args[0]} from storage`) {
      return Promise.reject(new Error(NOT_IN_STORAGE));
    } else {
      return Promise.reject(new Error(error.reason));
    }

  }
};

export const clearStorage = async () => {
  try {
    let response;
    if (detectedBridgePlatform){
      response = await ortaBridge.storage.clear();
    } else {
      console.log('no orta bridge support');
    }

    return response;
  } catch(error) {
    return Promise.reject(error);
  }
};

export const openSettings = async () => {
  try {
    let response = await ortaBridge.openSettings();

    return response;
  } catch(error) {
    console.log('openSettings error', error);
    return Promise.reject(error);
  }
};

export const openUrl = async (url) => {
  try {
    let response = await ortaBridge.openUrl(url);
    console.log('ortaBridge.openUrl', ortaBridge.openUrl);
    console.log('response', response);

    return response;
  } catch(error) {
    console.log('openUrl error', error);
    return Promise.reject(error);
  }
};

export const biometricAccess = async () => {
  try {
    let response = await ortaBridge.biometricAccess.authenticate();
    console.log('ortaBridge.biometricAccess', ortaBridge.biometricAccess.authenticate());
    console.log('response', response);

    return response;
  } catch(error) {
    console.log('biometricAccess error', error);
    return Promise.reject(error);
  }
};
